/*============================================*/
/*              Selector Components           */
/*============================================*/

.mc-select {
	$root: &;

	&[data-target='year'] {
		#{$root}__month {
			display: none;
		}
		#{$root}__year {
			width: 100%;
			#{$root}__data--year {
				width: 75%;
				max-width: unset;
				min-width: unset;
				justify-content: center;
			}
			#{$root}__nav {
				display: flex;
				position: relative;
			}
		}
	}

	&__month,
	&__year {
		display: flex;
		align-items: center;
	}
	&__nav {
		display: flex;
		outline: revert;
		align-items: center;
		position: absolute;
		text-decoration: none;
		color: $picker-header-active-color;
		padding: 0 1em;
		@include for-desktop {
			#{$modal} & {
				position: relative;
			}
		}

		&:focus {
			-webkit-tap-highlight-color: transparent;
			-ms-touch-action: manipulation;
			touch-action: manipulation;
		}

		&--inactive {
			color: $picker-header-inactive-color;
			cursor: default;
			&:active {
				transform: none !important;
			}
		}

		&--prev,
		&--next {
			transition: transform 0.2s ease-in-out;
		}
		&--prev {
			&:active {
				transform: translateX(-0.5em);
			}
			#{$inline} #{$root}__month &,
			& {
				left: 0;
			}
		}
		&--next {
			&:active {
				transform: translateX(0.5em);
			}
			#{$inline} #{$root}__month &,
			& {
				right: 0;
			}
		}

		#{$root}__year & {
			#{$inline} &,
			#{$permanent} &,
			& {
				display: none;
			}
			@include for-desktop {
				display: flex;
			}
		}
	}
	&__data {
		display: flex;
		align-items: center;
		@include font-size-clamp-polyfill(1rem, 8, 1.25rem);
		height: calc(8vw + 0.25rem);
		min-height: 1.75rem;
		max-height: 2rem;
		overflow: hidden;
		position: relative;
		cursor: pointer;

		&::after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			opacity: 0;
		}

		span {
			line-height: 1.2;
			text-align: center;
			position: absolute;
			#{$root}[data-target='year'] & {
				position: relative;
			}
		}

		&--month {
			&,
			span {
				width: 40vw;
				min-width: 5rem;
				max-width: 6.25rem;
			}
			#{$inline} & {
				&,
				span {
					width: 6.4rem;
				}
			}
		}

		&--year {
			&,
			span {
				width: 22vw;
				min-width: 3rem;
				max-width: 3.5rem;
			}
			#{$inline} & {
				&,
				span {
					width: 3.2rem;
				}
			}
		}
	}
}

.slide {
	&-right {
		&--in {
			animation: slide-right-in 200ms ease;
		}
		&--out {
			animation: slide-right-out 200ms ease;
		}
	}
	&-left {
		&--in {
			animation: slide-left-in 200ms ease;
		}
		&--out {
			animation: slide-left-out 200ms ease;
		}
	}
}
