.import-container{
  width:100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;   
  padding:10px 150px;
} 
.import-logo{
  width: 125px;
}

.import-header{
  width: calc(100%);
  padding-bottom:0px;
  border-bottom: 2px solid #F1F1F1;
  position:relative;
  min-width: 400px;
}
.import-header-title p{
  line-height:45px;
}
.import-header .import-header-title p{
  font-size: 24px;
}
.import-header-subtitle{
  padding:15px 15px;
  font-size: 15px;
}
.import-header-logo{
  position:absolute;
  top:-39px;
  right:0px;
}

.back-btn-container{
  position:relative;
}

.btn-back{
  position: absolute;
  left: -36px;
  top: 12px;
  color: #009644;
  font-size: 23px;
}

.import-btn{
  margin-top:10px;
} 

.import-body{
  /* width:50%; */
  align-items: center;   
  font-size: 0; /* Remove whitespace between inline-block elements */
}

.import-body-title{
  font-size: 20px;
  text-align: center;
  padding:20px 0px;
  font-weight: 500;
}
.component-container{
  display:flex;
  justify-content: center; /* Center the flex items horizontally */
  align-items: center; /* Center the flex items vertically */
}
.import-component{
  width:200px;
  height:300px;
  border-radius:8px;
  border: 2px solid #F1F1F1;
  display:flex;
  flex-direction: column;
  margin: 10px 15px;
  font-size: 20px;
  padding:0px 20px;
  text-align:center;
}
.component-content{
  height:85px;
}
.component-logo{
  height:125px;
  align-content: center;
}
.component-desc p{
  font-size:16px;
}
.component-button{
  padding:10px 5px;
  height:85px;
}
.import-component-btn{
  outline:none;
  background-color:#3a5291;
  box-shadow: none;
  border-radius: 3px;
  border: none;
  padding:10px 15px;
  color:white;
  font-size: 16px;
  margin-top:0px;
}
.import-header-logo{
  position:absolute;
  top:-39px;
  right:0px;
}
.import-btn{
  outline:none;
  box-shadow: none;
  border-radius: 3px;
  border: none;
  padding:10px 15px;    
  background-color: #009644;
  color: white !important;
}
.import-component-container{
  width:100%;
  min-width: 400px;
}
.import-component-content{
  padding: 15px 15px;
}
.import-menubar{
  display: flex;
}


.import-description p{
  font-size:15px;
  margin-bottom:20px;
}
.case-content, .dataset-content, .jaar-content, .administratie-content, .beginbalans-content, .balansverschil-content, .jaarafsluiting-content, .toggle-extra-content{
  margin: 10px 0px;
}
.case-select, .jaar-input{
  height: 36px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.428571429;
  color: #555;
  vertical-align: middle;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.case-option {
  font-size: 14px;
  padding: 6px 12px;
  color: #555;
  background-color: #fff;
}

.submit-btn{
  margin-right:4px;
}
.import-create-content{
  width:100%;
  display:flex;
  flex-direction: column;
}
.import-create-basic{
  padding-right:40px;
}

.import-create-extra{
  overflow:hidden;
  transition: all .3s ease;
  max-height:55px;
}

.toggle-extra-content{
  /* height: 57px; */
  position:relative;
  white-space: no-wrap;
}

.display-extra-btn{
  line-height: 1.428571429;
  position:relative;
  background-color:#009644;
  color:white;
  border-radius:4px;
  padding: 6px 10px;
  max-width:300px;
  min-width:200px;
  width:100%;
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.toggle-extra-icon{
  margin-left:6px;
}
.extra-content{
  opacity:0;
  transition: all .3s ease;
  margin-bottom: 10px;
}
.visible{
  opacity:1;
}
.reset-dropdown-btn{
  opacity:0;
}