
.custom-dropdown {
  position: relative;
  width: 100%;
  min-width: 200px;
  max-width: 300px;
  font-family: Arial, sans-serif;
  margin-top:5px;
}
.dropdown-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 12px;
  height: 36px;
  font-size: 14px;
  line-height: 1.428571429;
  color: #555;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
}

.dropdown-header .arrow {
  border: solid #555;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  transition: transform 0.3s;
}

.dropdown-header .arrow.open {
  transform: rotate(-135deg);
}

.dropdown-list {
  position: absolute;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 10;
  margin-top: 2px;
  max-height: 200px;
  overflow-y:auto;
}

.dropdown-item {
  padding: 6px 12px;
  font-size: 14px;
  color: #555;
  cursor: pointer;
}

.dropdown-item:hover, .dropdown-item.selected {
  background-color: #f0f0f0;
}

.dropdown-item.selected {
  font-weight: bold;
}
/* Remove default marker from list items */
.dropdown-list li::marker {
  content: none;
}
