.App-body{
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;   
  padding:10px 150px;
} 
.btn{
  outline:none;
  box-shadow: none;
  border-radius: 3px;
  border: none;
  padding:5px 10px;    
  margin-right:5px;
  background-color: #009644;
  color: white !important;
}
