.scheduler-component{
  position:absolute;
  z-index:98;
  background-color: white;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  padding:15px;
  display:flex;
  top: 0px;  
  left: 0px;
  flex-direction: column;
  border-radius: 8px;
  min-width: 300px;
}
.scheduler-close-window {
  color: #009644;
  position: absolute;
  right: 6px;
  top: 2px;
}

.scheduler-table{
  margin: 5px 0px;
}
.scheduler-tr{
  background-color: #f1f1f1;
}
.scheduler-td{
  /* width:200px; */
  padding-left: 8px;
}

.edit-scheduler-container{
  position: relative;
  width:0px;
  height:0px;
}
.scheduler-edit{
  border-bottom:1px solid #dddddd;
}
.scheduler-edit-btn{
  padding-left:3px;
  padding-right:2px;
}

.edit-scheduler{
  position: absolute;
  z-index: 99;
  left:16px;
  top:-5px;
  background-color: #f1f1f1;
  border: 1px solid #dddddd;
}
.edit-scheduler-td{
  width: 10px;
}
.edit-scheduler p {
  font-size: 14px;
  padding: 2px;
}

.datepicker-el{
  width: 0px;
  height: 0px;
  border:none;
  opacity: 0;
  /* display: none; */
}
.scheduled-wrapper-header{
  display:inline;
}

.scheduled-wrapper-date p{
  font-size: 15px;
}
.scheduled-status{ 
  margin-left:4px;
  font-size:16px;
}
.scheduled-wrapper .fa-xmark{
  color:red;
  top:1px;
  position:relative;  
}
.scheduled-wrapper .fa-check{
 color: #009644;
}
.scheduled-wrapper .fa-repeat, .scheduled-wrapper .fa-clock{
  color:rgb(0, 110, 255);
 }
