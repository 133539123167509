.app-footer {
  background-color: white;
  font-size: 26px;
  width: 100%;
  height:50px;
  margin-top: auto;
}
.app-bottom-bar{
  background-color:#F4F4F4;/*rgba(238, 238, 238, 1);*/
  text-align: center; 
  height:50px;
  box-shadow:  0 -3px 8px -2px  #E0E3E5; /*Upward fading shadow */  
  border-top: 1px solid #E0E3E5;
}
.footer-img{
  position:absolute;
  left:0px;
  height: 50px;
  padding:10px 20px;
  /* border-right: 1.5px solid #E0E3E5; */
}
.footer-title{
  display:inline-block;
  position:absolute;
  right: 55px;
}
.footer-title p{
  line-height:49px;
  color: #878788;
  font-size: 11px;
}

