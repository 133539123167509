.status-window{
  height: 218px;
  width: 350px;
  border: 1px solid #dddddd;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  position: absolute;
  border-radius:6px;
  right: 325px;
  z-index:99;
}
.status-header{
  position:sticky;
  background-color:#f1f1f1;
  top:0px;
  color:#666666;
  font-size: 20px;
  line-height: 30px;
  border-top-left-radius:6px;
  border-top-right-radius:6px;
  padding:0px 10px;
  box-shadow: 0 5px 6px 0px #E0E3E5; /* Downward fading shadow */
}
.scroll-container{
  overflow:auto;
  height: 170px;
  position:relative;
}
.status-container{
  display:flex;
  margin-top: 10px;
  flex-direction: column;
  padding: 0px 15px;
  padding-bottom:10px;
}
.check-icon{
  color: #009644;
}
.spinner-icon{
  color: #009644;
  animation: spin 1.25s linear infinite;
}
.progress-container{
  position:sticky;
  padding: 0px 15px;
  background-color:#f1f1f1;;
  border-bottom-left-radius:6px;
  border-bottom-right-radius:6px;
  box-shadow:  0 -4px 10px -2px #cbcbcb; /*Upward fading shadow */  
}
.status-bar{
  display:inline-block;
  height:10px;
  position:relative;
  top:2px;
}
.Minus-icon{
  color:#009644;
  font-size:12px;
  top: -2px;
  position:relative;
}
.progress-bar{
  width:10px;
  height:2px;
  background-color:#009644;
  transition: width .2s ease;
}
.load-spinner {
  position: relative;
  justify-content: center;
  align-items: center;
  display:inline-block;
  height: 100%;
  margin-left:5px;
}
.close-status-btn{
  margin-top:5px;
}
.status-close-window{
  font-size:15px;
  position:absolute;
  right: 3px;
  top: -3px;
  color:#009644;
  font-weight: 600;
}
.bar-0{
  background-color:red;
}
.type-error p{
  color:red;
  font-weight: 600;
}
.type-2 p{
  color:#009644;
  font-weight: 600;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}