.app-header {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 26px;
  width: 100%;
  height:80px;
  margin-bottom: 10px;
}
.app-top-bar{
  background-color:white;/*rgba(238, 238, 238, 1);*/
  border-bottom: 1px solid #E0E3E5;
  text-align: center; 
  width: 100%;
  height:80px;
  box-shadow: 0 4px 10px -2px #E0E3E5; /* Downward fading shadow */
}
.header-img{
  position:absolute;
  left:0px;
  height: 80px;
  padding:10px 20px;
  border-right: 1.5px solid #E0E3E5;
}
.header-title{
  display:inline-block;
  height:100%;
}
.header-title p{
  line-height:75px;
  color: #414142;
}
.language-selector{
  position:absolute;
  top:1px;
  font-size:0px;
}
.flag-icon{
  width:20px!important;
  height:20px!important;
}
.selected-language{
  height:17px;
}
.language-dropdown{
  border: 1px solid #ccc;
  border-radius: 4px;
  padding-right:2.5px;
  padding-left:3px;
  /* padding:2px; */
}
.language-dropdown li{
  height:20px;
}
.language-dropdown li::marker{
  content:none;
}
.language-container{
  position:absolute;
  top:30px;
  right:50px;
}
