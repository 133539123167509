/*============================================*/
/*              Display Components            */
/*============================================*/
.time-picker{
	display:flex;
	flex-direction: column;
	background-color: #f5f5f6;
}
.time-picker-header{
	padding-top:6px;
	box-sizing: border-box;
	height: 46px;
}
.time-picker-header p{
	color:rgb(129, 129, 129);
} 
.time-select-container{
	height:231px;	
	padding-right:10px;
	overflow-y: scroll;
}
.time-select-option{
	padding: 5px 5px;
	border-radius: 4px;
}
.time-select-option-disabled{
	opacity: 40%;
	padding: 5px 5px;
	border-radius: 4px;
}
.time-selected{
    background-color: #38ada9;
}
.weekday-selected{
	background-color: #38ada9;
	color: white!important;
}
.time-selected p{
	color: #ffffff;
}
.time-select-option:hover{
	opacity:.6;
	cursor:pointer;
}
.mc-table__weekday:hover{
	opacity:.6;
	cursor:pointer;
}
.mc-picker__weekday p{
	font-size: clamp(1rem, 8vw, 1.25rem);
	color: var(--mc-picker-header-active);
}
.mc-display {
	$root: &;
	display: none;
	color: $display-text-color;

	#{$modal} & {
		display: flex;
		flex-direction: column;
		max-height: 0;
		transition: max-height 0.2s linear;
		@include for-desktop {
			width: 200px;
			height: 100%;
			max-height: unset;
			background-color: $display-bg-color;
		}
	}

	#{$modal}#{$opened} & {
		max-height: 175px;
		animation: stretch 0.4s;

		@include for-desktop {
			transition: unset;
			max-height: unset;
			animation: unset;
		}
	}

	&__body {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0.5em 0;

		@include for-desktop {
			flex-direction: column;
			height: 100%;
			padding: 0;
		}
	}

	&__header {
		background-color: $display-bg-color-darker;
		#{$root}[data-target='month'] &,
		#{$root}[data-target='year'] & {
			@include for-desktop {
				display: none;
			}
		}
	}

	&__day {
		text-align: center;
		@include font-size-clamp-polyfill(1rem, 8, 1.25rem);
		line-height: 1;
		padding: 0.5em 0;
		#{$root}[data-target='month'] &,
		#{$root}[data-target='year'] & {
			visibility: hidden;
		}
		@include for-desktop {
			padding: 1em 0;
		}
	}

	&__data {
		display: flex;
		width: 50%;

		@include for-desktop {
			width: 100%;
			height: 50%;
			text-align: center;
		}

		&--primary {
			justify-content: flex-end;
			#{$root}[data-target='month'] &,
			#{$root}[data-target='year'] & {
				display: none;
			}

			@include for-desktop {
				justify-content: center;
				align-items: flex-end;
			}
		}
		&--secondary {
			flex-direction: column;
			#{$root}[data-target='month'] &,
			#{$root}[data-target='year'] & {
				width: 100%;
				text-align: center;
				@include for-desktop {
					justify-content: center;
					height: 100%;
				}
			}
			@include for-desktop {
				justify-content: space-between;
			}
		}
	}

	&__date {
		@include font-size-clamp-polyfill(5rem, 40, 7rem);
		line-height: 1;
	}

	&__month {
		@include font-size-clamp-polyfill(1.2rem, 9, 1.5rem);
		#{$root}[data-target='year'] & {
			display: none;
		}
		@include for-desktop {
			height: auto;
		}
	}

	&__year {
		@include font-size-clamp-polyfill(2.4rem, 18, 3rem);
		line-height: 0.8;
		#{$root}[data-target='year'] & {
			padding: 0.3em 0;
		}
		@include for-desktop {
			height: auto;
			padding: 0.5em 0;
		}
	}
}
