/*============================================*/
/*                 HTML Elements              */
/*============================================*/

/* Styling for bare HTML elements (like H1, A, header, footer, …).
These come with default styling from the browser so we must to redefine them here. */

.mc-calendar {
	h1,
	h2,
	h3 {
		font-weight: 500;
	}
}
