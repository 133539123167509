/*============================================*/
/*                Display Utility             */
/*============================================*/

.u-display-none {
	@include for-desktop {
		display: none !important;
	}
}

.mc-picker-vhidden {
	border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    top: 0;
    width: 1px;
}
